<template>
  <div class="footer py-14 mt-14">
    <div class="flex flex-col items-center gap-y-12">
      <nuxt-link to="/">
        <img src="/images/logo.png" alt="logo" class="w-48 h-auto" />
      </nuxt-link>
      <h3 class="text-2xl md:text-4xl text-gray-600">
        و أعدنا للبقلاوة.... كرامتها
      </h3>
    </div>

    <div class="container mt-14">
      <div class="flex flex-wrap gap-6 justify-center">
        <!-- <div class="ps-8">
          <h3 class="font-black md:text-lg footer-title">الأحكام العامة</h3>
          <ul class="text-gray-400 mt-3">
            <li class="md:text-lg py-1 font-bold">خيارات الدفع والتوصيل</li>
            <li class="md:text-lg py-1 font-bold">سياسة الخصوصية</li>
            <li class="md:text-lg py-1 font-bold">سياسة الخصوصية</li>
          </ul>
        </div>

        <div class="ps-8">
          <h3 class="font-black md:text-lg footer-title">طرق الدفع</h3>
          <div class="flex flex-wrap gap-3 mt-4">
            <div
              v-for="(item, index) in payments"
              :key="index"
              class="bg-white rounded-full overflow-hidden w-16 h-16 flex items-center justify-center"
            >
              <img
                :src="`/images/payments/${item}.png`"
                alt="mada"
                class="w-10 h-auto object-contain"
              />
            </div>
          </div>
        </div> -->

        <div class="ps-8">
          <h3 class="font-black md:text-lg footer-title">تابعنا</h3>
          <app-social
            class="mt-4"
            :facebook="value?.social_links?.facebook"
            :instagram="value?.social_links?.instagram"
            :tiktok="value?.social_links?.tiktok"
            :twitter="value?.social_links?.twitter"
            :whatsapp="value?.social_links?.whatsapp"
          />
        </div>
      </div>
      <div class="flex items-center justify-center gap-3 mt-6">
        <nuxt-link to="/privacy" class="text-yellow-400 underline text-sm"
          >سياسة الخصوصية</nuxt-link
        >
        <nuxt-link to="/terms" class="text-yellow-400 underline text-sm"
          >الشروط والاحكام
        </nuxt-link>
      </div>
    </div>
  </div>

  <div class="text-white bg-green-400 md:text-lg font-bold py-4 text-center">
    <p>جميع الحقوق محفوظة @لمصنع الطبيعة</p>
  </div>
</template>

<script lang="ts" setup>
const {value} = useStatic();
</script>

<style lang="postcss" scoped>
.footer {
  background: url("/images/footer-bg.webp") no-repeat center center;
  background-size: cover;
  &-title {
    @apply relative before:content-[''] before:absolute before:top-1/2 before:-translate-y-1/2 before:-start-7 before:w-6 before:h-1 before:rounded-md before:bg-yellow-400;
  }
}
</style>
